import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../lib/axios";
import "./AdminSinglePage.scss";
import AdminAddStudentToGroup from "./AdminAddStudentToGroup";
import { Delete, KeyboardReturn } from "@mui/icons-material";

export default function AdminSinglePage() {
  const useAuth = window.localStorage;
  const { id: groupID } = useParams();
  const Token = useAuth.getItem("accessToken");
  const [group, setGroup] = useState();
  const [students, setStudents] = useState();
  const [groupId, setGroupId] = useState(groupID);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get(`group/${groupID}`, config).then((response) => {
      setGroup(response.data);
    });
  }, [groupID]);

  useEffect(() => {
    axios
      .get(`groups/students/${groupID}`, config)
      .then((response) => {
        console.log(response.data);
        setStudents(response.data.students);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [groupID]);

  const deleteLesson = (studentId) => {
    if (confirm("O'chirishni istaysizmi?")) {
      setGroupId(groupID);
      axios
        .delete(`group/del-student?gid=${groupId}&sid=${studentId}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(group);

  return (
    <>
      <section className="admin-single-group-section">
        <div className="container">
          <div className="admin-single">
            <Link to="/ad-groups" className="admin-single__header">
              <h1 className="admin-single__title">{group?.group_name}</h1>
              <KeyboardReturn/>
            </Link>
            {group && <div className="admin-single__wrapper">
              <div className="admin-single__teacher">
                <strong>Ustoz</strong>: {group?.teacher_name ? group?.teacher_name : "Ustoz topilmadi."}
              </div>
              <div>
                <strong>Boshlangan sana: </strong> {group?.start_date.split("T")[0]}
              </div>
              <div>
                <strong>Tugash sanasi: </strong> {group?.end_date ? group?.end_date.split("T")[0] : "Noma'lum"}
              </div>
              <div>
                <strong>Yo`nalish: </strong> {group?.tag_name}
              </div>
              <div>
                <strong>Dars vaqti: </strong> {group?.start_time.split("T")[1].slice(0, 5)} - {group?.end_time.split("T")[1].slice(0, 5)}
              </div>
            </div>}
            {group === undefined ? (
          <section className="mygroup-section tech-lesson-section">
          </section>
            ) : (
          <section className="mygroup-section tech-lesson-section admin-group-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Talabalar</th>
                      <th className="add-wrapper">
                        <AdminAddStudentToGroup />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                              {item.first_name} {item.last_name}
                          </td>
                          <td className="add-wrapper">
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talabalar</div>
                    <AdminAddStudentToGroup />
                  </div>
                  <div className="mygroup__table-body">
                    {students &&
                      students.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div style={{ padding: "16px" }} className=""><p>{index + 1}</p></div>
                          <div style={{ padding: "16px" }} className="group-name">
                            <p>{item.first_name} {item.last_name}</p>
                          </div>
                          <div>
                            <button
                                className="btn-delete"
                                onClick={() => deleteLesson(item.id)}
                                style={{ color: "crimson" }}
                              >
                                <Delete />
                              </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
